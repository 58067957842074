<template>
  <v-app-bar app hide-on-scroll color="primary" flat>
    <div @click="open()" class="d-flex align-center" style="cursor:pointer">
      <v-img alt="BuyCo Logo" class="shrink mt-1 ml-n7" contain
        src="https://s3.amazonaws.com/buyco.assets/images/newLogoWhite.png" width="12em" />
    </div>
    <v-spacer></v-spacer>
    <v-btn v-if="logado" rounded elevation="0" dark color="#FFFFFF05" class="mx-1 text-capitalize" @click="logout()">
      Deslogar </v-btn>
    <v-btn text rounded color="white" @click="whats()"> Ajuda </v-btn>
  </v-app-bar>
</template>

<script>
export default ({
  data() {
    return {
      logado: false,
    }
  },
  methods: {
    whats() {
      window.open(
        `https://api.whatsapp.com/send?phone=553183532076&text=Olá!`,
        "_blank"
      );
    },
    open() {
      window.open("https://www.buyco.com.br/blog/", "_blank");
    },
  },
  mounted() {
  },
})
</script>
