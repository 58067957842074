import FingerprintJS from '@fingerprintjs/fingerprintjs';
import gql from "graphql-tag";
import numeral from "numeral";
import { apolloClient } from '@/vue-apollo'
import {getCNPJ} from '@/service/getCnpj.js'
import {sendToHubspot} from '@/service/sendToHubspot.js'

class calculatorLead {
    data = null
    profile = null
    lead = null
    computer_id = null
    source = null
    medium = null
    campaign = null
    dadosHubspot = {}
    razaoSocial = null
    constructor(data, qq) {
        this.data = data
        if(!!qq){
            this.source = qq.utm_source
            this.medium = qq.utm_medium
            this.campaign = qq.utm_campaign
        }
    }
    async init(){
        await this.getFigerprint()
        await this.getProfile()
        await this.getLead()
        await this.saveData()
        await this.generatePDF()
    }
    async getFigerprint(){
        let fp = await FingerprintJS.load()
        let result = await fp.get()
        this.computer_id = result.visitorId
    }
    async getProfile(){
        let profile_num = "0"
        if(parseInt(numeral(this.data.faturamento).value()) >= 400000 && this.data.motivo == "vender"){
            profile_num = "0"
        }
        try {
            let query = await apolloClient.query({
                query: gql`
                    query MyQuery($name: String!) {
                        calculator_profiles(where: {name: {_eq: $name}}) {
                            id
                            video
                            text
                            call_to_action
                            url
                            time
                        }
                    }
                `,
                variables: {
                    name: profile_num
                }
            });
            this.profile = query.data.calculator_profiles[0];
        } catch (error) {
          console.log("error: ", error);
        }
    }
    async getLead(){
        try {
            let result = await apolloClient.mutate({
                mutation: gql`
                    mutation MyMutation($computer_id: String!, $profile: uuid!, $name: String!, $email: String!, $phone: String!, $city: uuid!, $source: String, $medium: String, $campaign: String) {
                        insert_calculator_lead(objects: {computer_id: $computer_id, profile: $profile, name: $name, email: $email, phone: $phone, city: $city, source: $source, medium: $medium, campaign: $campaign}) {
                            returning {
                                id
                            }
                        }
                    }
                `,
                variables: {
                    computer_id: this.computer_id,
                    profile: this.profile.id,
                    name: this.data.nome,
                    email: this.data.email,
                    phone: this.data.phone,
                    city: this.data.cidade,
                    source: this.source,
                    medium: this.medium,
                    campaign: this.campaign,
                }
            })
            this.lead = result.data.insert_calculator_lead.returning[0].id
        } catch (error) {
          console.log("error: ", error);
        }
    }
    async generatePDF(){
        try {
            let result = await apolloClient.mutate({
                mutation: gql`
                    mutation MyMutation($id: uuid!) {
                        StartCalculator(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: this.lead,
                }
            })
            await this.saveDataHubspot()
        } catch (error) {
          console.log("error: ", error);
        }

    }
    async saveData(){
        await this.saveCompanyData()
        await this.saveValues()
    }
    async saveCompanyData(){
        let age
        switch (this.data.idade){
            // {text: "Menos de 1 ano", value: 1},
            // {text: "De 1 a 5 anos", value: 3},
            // {text: "De 6 a 10 anos", value: 8},
            // {text: "De 11 a 15 anos", value: 13},
            // {text: "Mais de 15 anos", value: 15}],
            case "Menos de 1 ano":
                age = 1
                break;
            case "De 1 a 5 anos":
                age = 3
                break;
            case "De 6 a 10 anos":
                age = 8
                break;
            case "De 11 a 15 anos":
                age = 13
                break;
            case "Mais de 15 anos":
                age = 15
                break;
            default:
                age = this.data.idade
                break;
        }

        this.dadosHubspot.idade = age
        
        let share_capital = null
        if(!!this.data.cnpj){
            share_capital = await getCNPJ(this.data.cnpj.replace(/\D/g,''))
            if(share_capital.result.length > 17){
                this.razaoSocial = share_capital.result[2]
                share_capital = parseInt(share_capital.result[17])
            }else{
                share_capital = null
            }
        }

        try {
            // console.log(this.data)
            let result = await apolloClient.mutate({
                mutation: gql`
                mutation MyMutation($lead: uuid!, $age: Int, $cnpj: String, $share_capital: Int, $type: uuid!, $other_type: String, $reason: String!, $other_reason: String, $employees: Int, $company_name: String, $fancy_name: String, $company_moment: String, $trademark: Boolean) {
                    insert_calculator_company_data(objects: {lead: $lead, age: $age, cnpj: $cnpj, share_capital: $share_capital, type: $type, other_type: $other_type, reason: $reason, other_reason: $other_reason, employees: $employees, company_name: $company_name, fancy_name: $fancy_name, company_moment: $company_moment, trademark : $trademark }) {
                      affected_rows
                    }
                  }
                `,
                variables: {
                    lead: this.lead,
                    age: age,
                    cnpj: !!this.data.cnpj ? this.data.cnpj.replace(/\D/g,'') : null,
                    share_capital: share_capital,
                    type: this.data.tipo,
                    other_type: this.data.outroTipo,
                    reason: this.data.motivo,
                    employees: this.data.funcionarios,
                    company_moment: this.data.momento,
                    company_name: this.data.companyName,
                    fancy_name: this.data.fancyName,
                    other_reason: this.data.outroMotivo,
                    trademark: !!this.data.marcaRegistrada
                }
            })
        } catch (error) {
          console.log("error: ", error);
        }   
    }
    async saveValues(){
        try {
            let query = await apolloClient.query({
                query: gql`
                query MyQuery {
                        calculator_values_type {
                            value
                        }
                    }
                `,
            });
            let valueTypes = query.data.calculator_values_type;
            valueTypes.forEach(async (v) => {
                let type = v.value
                let result = await apolloClient.mutate({
                    mutation: gql`
                        mutation MyMutation($lead: uuid!, $type: String!, $value: numeric!) {
                            insert_calculator_values(objects: {lead: $lead, type: $type, value: $value}) {
                                affected_rows
                            }
                        }
                    `,
                    variables: {
                        lead: this.lead,
                        type: type,
                        value: parseInt(numeral(this.data[type]).value())
                    }
                }) 
            })
        } catch (error) {
          console.log("error: ", error);
        }
    }
    async saveDataHubspot(){
        // console.log(this.data.cidade)

        let cidade = await apolloClient.query({
            query: gql`
                query MyQuery($id: uuid!) {
                    cities_by_pk(id: $id) {
                        stateByState {
                            initials
                        }
                        name
                    }
                }
            `,
            variables: {
                id: this.data.cidade
            }
        });
            
        let tipoN = await apolloClient.query({
            query: gql`
                query MyQuery($id: uuid!) {
                    company_types_by_pk(id: $id) {
                        name
                        branchByBranch {
                            sectorBySector {
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.data.tipo
            }
        });
        
            
        let motivo = await apolloClient.query({
            query: gql`
                query MyQuery($id: String!) {
                    reasons_type_by_pk(value: $id) {
                        description
                    }
                }
            `,
            variables: {
                id: this.data.motivo
            }
        });
        motivo = motivo.data.reasons_type_by_pk.description

        let tipo = !!this.data.outroTipo ? this.data.outroTipo : tipoN.data.company_types_by_pk.name
        let setor = tipoN.data.company_types_by_pk.branchByBranch.sectorBySector.name
        // console.log("this.data", this.data)
        // console.log("this.razaoSocial", this.razaoSocial)
        // console.log("!!this.razaoSocial", !!this.razaoSocial)
        // console.log("this.data.company_name", this.data.company_name)
        // console.log("if", !!this.razaoSocial ? this.razaoSocial : this.data.company_name)
        this.dadosHubspot = {...this.dadosHubspot, ...{
            lead: this.lead,
            campaign: this.campaign,
            cidade: cidade.data.cities_by_pk.name,
            cnpj: !!this.data.cnpj ? this.data.cnpj : "",
            dividas: this.data.dividas,
            email: this.data.email,
            estado: cidade.data.cities_by_pk.stateByState.initials,
            faturamento: this.data.faturamento,
            funcionarios: this.data.funcionarios,
            lucro: this.data.lucro,
            mailing: true,
            marcaRegistrada: this.data.marcaRegistrada ? "Sim" : "Não",
            medium: this.medium,
            momento: this.data.momento,
            motivo: motivo,
            nome: this.data.nome,
            outroMotivo: this.data.outroMotivo,
            razao: !!this.razaoSocial ? this.razaoSocial : this.data.companyName,
            setor: setor,
            source: this.source,
            telefone: this.data.phone,
            tipo: tipo,
        }} 
        let data = this.dadosHubspot
        let fat = data.faturamento.replace(",00","").replace("R$ ","").replace(/\./g,'')
        fat = parseInt(fat)
        // console.log(fat)
        let porte = fat <= 30000 ? "Micro" : (fat <=400000 ? "Pequeno" : "Médio")
        let texto = "Micro Empresa"
        if (fat >= 30000) {
            if (motivo == "Quero vender" || motivo == "Captação de investimento") {
                texto = "Empresa a Venda"
            } else {
                texto = "Empresa Patrimônio"
            }
        }

        // console.log("dataaaa", data)
        await sendToHubspot('Calculadora de Valuation',
                        data.nome,
                        data.email,
                        data.telefone, 
                        data.mailing, 
                        data.cidade,
                        data.estado, 
                        data.idade, 
                        data.cnpj, 
                        data.faturamento, 
                        data.lucro, 
                        data.motivo, 
                        data.outroMotivo, 
                        data.tipo, 
                        data.razao, 
                        data.setor, 
                        porte, 
                        data.funcionarios, 
                        data.momento, 
                        data.marcaRegistrada,
                        data.source, 
                        data.medium, 
                        data.campaign,
                        data.dividas,
                        data.lead
                        )
        // let titulo = fat <= 40000 ? "Calculadora de Valuation - Avaliação Digital" : (fat <=400000 ? "Calculadora de Valuation - Avaliação Consultiva" : "Calculadora de Valuation - Valuation BuyCo")
        // await sendToHubspot(titulo, data.nome, data.email, data.telefone, data.mailing, data.cidade, data.estado, data.idade, data.cnpj, data.faturamento, data.lucro, data.motivo, data.outroMotivo, data.tipo, data.razao, data.setor, porte, data.source, data.medium, data.campaign)
    }
}
  
export default calculatorLead
