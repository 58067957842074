<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>
  
<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "FancyName",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          autofocus: true,
          type: "text",
          key: "fancyName",
          label: "Nome da Empresa",
          dense: true,
          background: "#FFFD",
          rules: [
            (v) =>
              !!v || "Insira o nome fantasia da empresa",
          ],
          hideDetails: false,
          outlined: true,
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
    this.getStoreCompanyName()
    this.checkStoreFancyName()
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
    getStoreCompanyName() {
      this.dataForms['companyName'] = this.$store.state.companyName
      this.$emit('saveData', this.dataForms)
    },
    checkStoreFancyName() {
      if (!this.$store.state.companyFancyName) return
      this.dataForms['fancyName'] = this.$store.state.companyFancyName
      this.$emit('saveData', this.dataForms)
      this.$emit('skipStep')
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>