<template>
    <v-footer color="white">
      <v-card flat tile width="100%" color="white">
        <v-row justify="center" align="start" class="my-5">

          <v-col md="3" lg="2" xl="2">
            <v-img
              alt="BuyCo Logo"
              class="shrink"
              contain
              src="https://buyco.com.br/wp-content/themes/buyco/assets/images/logo.svg"
              width="7.5em"
            />

            <v-card-text class="primary--text pa-0 mt-2">
              ®BuyCo. Plataforma que usa a tecnologia para avaliar e intermediar a compra e venda de empresas.
            </v-card-text>

            <v-card-text class="pa-0 ml-n4">
              <v-btn
                v-for="(icon, i) in icons"
                :key="i"
                class="mx-4 my-2 primary--text"
                fab
                small
                elevation="2"
                :href="links[i]"
              >
                <v-icon size="24px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-col>

          <v-col md="2" xl="1">
            <p class="font-weight-black text-h4 text-xl-h5 primary--text mt-n1 mb-1">Avaliações</p>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/avaliacao-digital/${utmParams}`">
              Avaliação Digital
            </a>
            <br>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/avaliacao-consultiva/${utmParams}`">
              Avaliação Consultiva
            </a>
            <br>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/valuation-buyco/${utmParams}`">
              Valuation BuyCo.
            </a>
          </v-col>

          <v-col md="2" xl="1">
            <p class="font-weight-black text-h4 text-xl-h5 primary--text mt-n1 mb-1">Serviços</p>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/avaliar/${utmParams}`">
              Avaliar
            </a>
            <br>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/vender/${utmParams}`">
              Vender
            </a>
            <br>
            <a class="font-weight-light text-decoration-none" :href="`https://buyco.com.br/comprar/${utmParams}`">
              Comprar
            </a>
          </v-col>

          <v-col md="2">
            <p class="font-weight-black text-h4 text-xl-h5 primary--text mt-n1 mb-1">Contatos</p>
            <p class="font-weight-light mb-0">
              Nosso WhatsApp
            </p>
            <a @click="whats" class="cursor-pointer font-weight-bold mb-0 mt-n2">
              (31) 3658-3381
            </a>
            <p class="font-weight-light mb-0">
              Nosso telefone
            </p>
            <p class="font-weight-bold mb-0 mt-n2">
              (31) 3658-3381
            </p>
            <p class="font-weight-light mb-0">
              Nosso Endereço
            </p>
            <p class="font-weight-bold mb-0 mt-n2">
              Av. Amazonas, 2095
            </p>
            <p class="font-weight-light mb-0 mt-n1">
              4º e 5º Andares - Santo Agostinho, Belo Horizonte - MG, 30180-001.
            </p>
            <p class="font-weight-light mb-0">
              Nosso e-mail
            </p>
            <p class="font-weight-bold mb-0 mt-n2">
              contato@buyco.com.br
            </p>
            
          </v-col>

        </v-row>
        
        <v-row justify="center" align="center">
          <v-col md="9" lg="8" xl="6">
            <v-divider class="primary"></v-divider>
          </v-col>
        </v-row>


        <v-card-text class="primary--text text-center">
          ®Copyright © BuyCo. - {{ new Date().getFullYear() }}. Todos os direitos reservados
          <br>
          <a @click="termsOfService()"> <u>Termos de uso e privacidade.</u> </a>
        </v-card-text>
      </v-card>
    </v-footer>
</template>
<script>

export default({
    data: () => ({
    utmParams: '',
    links:["https://www.facebook.com/buycobrasil/", "https://www.youtube.com/channel/UCWLwR43h9Qgk3SHUJc7J87Q", "https://www.linkedin.com/company/buyco-brasil/", "https://www.instagram.com/buycobrasil/"],
    icons: ["mdi-facebook", "mdi-youtube", "mdi-linkedin", "mdi-instagram"],
  }),
  methods: {
    termsOfService(){
      window.open("https://wp-buyco-2021.s3.amazonaws.com/wp-content/uploads/2023/11/TERMO-DE-USO-E-POLITICA-DE-PRIVACIDADE-BUYCO.pdf", "_blank");
    },
    whats(){
      window.open("https://api.whatsapp.com/send?phone=+553136583381&text=Olá!", "_blank");
    },
    setLinks(){
      const queryParams = this.$route.query
      const utmParamArray = []
      if(queryParams.utm_source) utmParamArray.push(`utm_source=${queryParams.utm_source}`)
      if(queryParams.utm_medium) utmParamArray.push(`utm_medium=${queryParams.utm_medium}`)
      if(queryParams.utm_campaign) utmParamArray.push(`utm_campaign=${queryParams.utm_campaign}`)

      this.utmParams = `${utmParamArray.length>0? '?': ''}${utmParamArray.join('&')}`
    }
  },
  mounted(){
    this.setLinks()
  }
})
</script>
<style scoped>

</style>
