import { render, staticRenderFns } from "./PDFuploader.vue?vue&type=template&id=7f50ccdd&scoped=true&"
import script from "./PDFuploader.vue?vue&type=script&lang=js&"
export * from "./PDFuploader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f50ccdd",
  null
  
)

export default component.exports