<template>
  <v-card-actions>
    <v-row justify="center">
      <v-col justify="center" align="center" cols="12">
        <v-sheet v-if="!calculating" class="rounded d-inline-flex pa-1">
          <v-btn v-if="previousDisabled" color="#FFF0" depressed :disabled="nextDisabled" @click="next()">
            Iniciar
          </v-btn>
          <div v-else>
            <v-btn color="#FFF0" depressed :disabled="loading" @click="previous()">
              Voltar
            </v-btn>
            <v-btn class="ml-1" color="primary" depressed :loading="loading" :disabled="nextDisabled" @click="next()">
              {{ inLastStep ? "Calcular" : "Próximo" }}
            </v-btn>
          </div>
        </v-sheet>

        <div v-else class="py-1 mt-8">
          <div v-if="done">
            <v-btn class="mb-2" color="#FFF" depressed @click="restart()">
              Refazer
            </v-btn>
            <v-btn class="ml-1 mb-2 white--text" style="filter: drop-shadow(0px 4px 10px rgba(107, 93, 232, 0.3));"
              color="primary" depressed @click="openResult()">
              {{ callToAction }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>
export default {
  name: "StepButtons",
  components: {
  },
  props: ['nextDisabled', 'previousDisabled', 'inLastStep', 'calculating', 'done', 'callToAction'], 
  data() {
    return {
    }
  },
  methods: {
    home() {
      this.$router.push('/')
    },
    next() {
      this.$emit('next')
    },
    previous() {
      this.$emit('previous')
    },
    restart() {
      this.$emit('restart')
    },
    openResult() {
      this.$emit('openResult')
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
  },

}
</script>