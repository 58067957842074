<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"
import gql from "graphql-tag";

export default {
  name: "Motivo",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
      reasons: [],
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "autocomplete",
          key: "motivo",
          label: "Motivo da Avaliação",
          dense: true,
          items: this.reasons,
          background: "#FFFD",
          hideDetails: false,
          outlined: true,
          rules: [(v) => !!v || "Insira o motivo da avaliação"],
          doubt: {
            color: "#F1B71C33",
            iconColor: "warning",
            iconSize: "35px",
            size: "45px",
            class: "pa-1",
            title: "Motivo da Avaliação",
            text: "<p>Indique o principal motivo pelo qual você está avaliando sua empresa. Os principais motivos são:</p><ul><li>Quero vender</li><li>Alteração no quadro de sócios</li><li>Aposentadoria/Sucessão/Herança</li><li>Captação de investimento</li><li>Negociar Dívidas</li><li>Gestrão e acompanhamento</li></ul><br><p> Caso você não se encaixe em nenhum dos casos, selecione 'Outros' e informe o motivo da avaliação</p>",
            link: this.linkDuvida,
            linkText: "Ainda estou com dúvidas",
            close: "Entendi",
          },
          stylization: "margin-bottom: -35px;",
        },
        {
          type: "text",
          key: "outroMotivo",
          label: "Escreva seu motivo",
          dense: true,
          hide: this.dataForms.motivo != "outros",
          background: "#FFFD",
          rules: [
            (v) =>
              !!v || this.dataForms.motivo != "outros" || "Insira o motivo",
          ],
          hideDetails: false,
          outlined: true,
          stylization: "margin-bottom: -35px;",
        },
      ];
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
    this.reasons = this.$store.state.reasons
  },
  async mounted() {
    if (this.reasons > 0) return
    this.$store.dispatch('changeLoading', true)
    await this.getReasons()
    this.$store.dispatch('saveReasons', this.reasons)
    this.$store.dispatch('changeLoading', false)
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
    async getReasons() {
      try {
        let query = await this.$apollo.query({
          query: gql`
            query MyQuery {
              reasons_type {
                value
                description
              }
            }
          `,
        })
        let result = query.data.reasons_type;
        this.reasons = []
        result.forEach((x) => {
          this.reasons.push({ text: x.description, value: x.value })
        })
      } catch (error) { //TODO: Tratar erro na query
        console.log("error: ", error)
      }
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>