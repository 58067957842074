<template>
  <div style="position: relative; height: 230px; ">
    <div style="position: absolute; width: 100%; height: 100%;">
      <div v-if="!loading"  class="container-iframe">
        <iframe ref="video" width="400" height="215" class="responsive-iframe px-2 py-2" :src="embedUrl"
          title="Video player" frameborder="0" allow="autoplay; encrypted-media;"></iframe>
      </div>
      <div class="mt-4">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <label class="textDesktopBlack">
          Sua avaliação está sendo processada...</label>
      </div>
    </div>
    <div style="position: absolute; width: 100%; height: 120%; display: none;">
    </div>
  </div>
</template>
    
<script>
export default {
  name: "Calculating",
  components: {
  },
  props: ["embedUrl"],
  data() {
    return {
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
  },
}
</script>