<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "Nome",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          autofocus: true,
          type: "text",
          key: "nome",
          label: "Nome completo",
          dense: true,
          background: "#FFFD",
          outlined: true,
          rules: [
            (v) => !!v || "Insira seu nome completo",
            (v) => v.split(" ").length > 1 || "Insira seu nome completo",
          ],
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = {...this.filledData}
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>