<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "MarcaRegistrada",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "switch",
          key: "marcaRegistrada",
          label: "Sua empresa possui Marca Registrada?",
          dense: true,
          hideDetails: false,
          outlined: true,
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = {marcaRegistrada: false, ...this.filledData} //Added key because the switch input has to be activated to update dataForms
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>