<template>
  <div>
    <!-- mobile começo -->
    <div class="d-sm-none">
      <v-carousel v-model="carousel" height="700px" class="my-4 mt-15" hide-delimiters :show-arrows="false">
        <v-carousel-item>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-card height="276px" width="280px" color="white" class="d-flex mt-10 mx-4 rounded-lg zIndex0"
                elevation="6">
                <v-img src="https://s3.amazonaws.com/buyco.assets/images/Foto+do+time.jpeg" />
              </v-card>
              <svg class="arrows zIndex-1" xmlns="http://www.w3.org/2000/svg" width="57" height="180" viewBox="0 0 19 60"
                fill="none">
                <path d="M9.60999 28.89L16.45 35.72L18.46 33.71L9.60999 24.86L0.77002 33.71L2.78003 35.72L9.60999 28.89Z"
                  fill="#F1B71C" />
                <path d="M9.60999 40.82L16.45 47.65L18.46 45.64L9.60999 36.79L0.77002 45.64L2.78003 47.65L9.60999 40.82Z"
                  fill="#F1B71C" />
                <path d="M9.83997 52.89L16.68 59.73L18.69 57.71L9.83997 48.87L1 57.71L3.01001 59.73L9.83997 52.89Z"
                  fill="#F1B71C" />
                <path
                  d="M9.60999 4.89001L16.45 11.72L18.46 9.70999L9.60999 0.859985L0.77002 9.70999L2.78003 11.72L9.60999 4.89001Z"
                  fill="#F1B71C" />
                <path d="M9.60999 16.82L16.45 23.65L18.46 21.64L9.60999 12.79L0.77002 21.64L2.78003 23.65L9.60999 16.82Z"
                  fill="#F1B71C" />
              </svg>
              <svg class="meioCirculo zIndex1" xmlns="http://www.w3.org/2000/svg" width="141" height="70"
                viewBox="0 0 94 47" fill="none">
                <path
                  d="M46.5858 46.7941C72.4272 46.7941 93.3799 25.8469 93.3799 0L75.0086 0C75.0086 15.6967 62.2825 28.4228 46.5858 28.4228L46.5858 46.7941Z"
                  fill="#F1B71C" />
                <path
                  d="M46.7941 46.7941C20.9527 46.7941 0 25.8469 0 0L18.3713 0C18.3713 15.6967 31.0974 28.4228 46.7941 28.4228V46.7941Z"
                  fill="#F1B71C" />
              </svg>
            </v-col>
            <v-col cols="10" class="mt-n5" justify="center" align="center">
              <h2>
                <strong class="primary--text">Sobre a BuyCo</strong><strong class="secondary--text">.</strong>
              </h2>
            </v-col>
            <v-col cols="10" class="mt-n3" justify="center" align="center">
              <h4 class="primary--text">
                Ao longo de 10 anos de experiência, desenvolvemos uma
                metodologia que, com a ajuda da tecnologia e de um algoritmo
                de inteligência artificial, resultou em uma poderosa
                calculadora para dizer o valor justo da sua empresa de forma
                100% digital e gratuita!
              </h4>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <v-btn color="#E85D75" href="https://www.buyco.com.br/" class="dropShadow white--text" elevation="0" large>Acesse
                nosso site</v-btn>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-card height="190px" width="280px" color="white" class="d-flex mt-10 mx-4 rounded-lg zIndex0"
                elevation="6">
                <v-img
                  src="https://www.signupgenius.com/cms/socialMediaImages/tips-make-the-most-of-company-business-meetings-article-1200x800.jpg" />
              </v-card>
            </v-col>
            <v-col cols="11" class="mt-n3" justify="center" align="center">
              <h4 class="primary--text">
                A BuyCo. é especialista em avaliar empresas e vai te ajudar a
                descobrir quanto vale o seu maior patrimônio. Basta preencher
                os dados da sua empresa no nosso formulário e, em 5 minutos, o
                valor será enviado para o seu e-mail. Não se preocupe, seus
                dados serão usados unicamente para o cálculo! Essa calculadora
                é uma versão simplificada que considera poucos dados
                informados por você para estimar o valor da sua empresa e, por
                isso, a BuyCo. não se responsabiliza pelos dados fornecidos e
                gerados. Além disso, a calculadora não descarta a necessidade
                da contratação de um consultor BuyCo. especialista em
                avaliação de empresas.
              </h4>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <v-btn color="primary" href="https://www.buyco.com.br/" class="dropShadowPrimary" elevation="0" large>Saiba
                mais</v-btn>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-col cols="10" justify="center" align="center" class="mt-n10 ml-8 mb-4">
        <v-icon v-for="(car, i) in 2" :key="i" class="mx-2 red--text text--lighten-4"
          v-bind:class="{ 'red--text text--lighten-1': carousel === i }" size="12px">
          mdi-circle
        </v-icon>
      </v-col>
    </div>
    <!-- mobile fim -->
    <!-- desktop início -->
    <div class="d-none d-sm-block my-10">
      <v-carousel v-model="carouselDesktop" height="400px" class="my-4 mt-15" hide-delimiters :show-arrows="true">
        <v-carousel-item>
          <v-row justify="center" align="center">
            <v-col xl="2" lg="3" md="4" sm="5" justify="center" align="center">
              <v-card height="276px" width="280px" color="white" class="d-flex mt-10 mx-4 rounded-lg zIndex0"
                elevation="6">
                <v-img src="https://s3.amazonaws.com/buyco.assets/images/Foto+do+time.jpeg" />
              </v-card>
              <svg class="arrows zIndex-1" xmlns="http://www.w3.org/2000/svg" width="57" height="180" viewBox="0 0 19 60"
                fill="none">
                <path d="M9.60999 28.89L16.45 35.72L18.46 33.71L9.60999 24.86L0.77002 33.71L2.78003 35.72L9.60999 28.89Z"
                  fill="#F1B71C" />
                <path d="M9.60999 40.82L16.45 47.65L18.46 45.64L9.60999 36.79L0.77002 45.64L2.78003 47.65L9.60999 40.82Z"
                  fill="#F1B71C" />
                <path d="M9.83997 52.89L16.68 59.73L18.69 57.71L9.83997 48.87L1 57.71L3.01001 59.73L9.83997 52.89Z"
                  fill="#F1B71C" />
                <path
                  d="M9.60999 4.89001L16.45 11.72L18.46 9.70999L9.60999 0.859985L0.77002 9.70999L2.78003 11.72L9.60999 4.89001Z"
                  fill="#F1B71C" />
                <path d="M9.60999 16.82L16.45 23.65L18.46 21.64L9.60999 12.79L0.77002 21.64L2.78003 23.65L9.60999 16.82Z"
                  fill="#F1B71C" />
              </svg>
              <svg class="meioCirculo zIndex1" xmlns="http://www.w3.org/2000/svg" width="141" height="70"
                viewBox="0 0 94 47" fill="none">
                <path
                  d="M46.5858 46.7941C72.4272 46.7941 93.3799 25.8469 93.3799 0L75.0086 0C75.0086 15.6967 62.2825 28.4228 46.5858 28.4228L46.5858 46.7941Z"
                  fill="#F1B71C" />
                <path
                  d="M46.7941 46.7941C20.9527 46.7941 0 25.8469 0 0L18.3713 0C18.3713 15.6967 31.0974 28.4228 46.7941 28.4228V46.7941Z"
                  fill="#F1B71C" />
              </svg>
            </v-col>
            <!-- <v-col cols="1"></v-col> -->
            <v-col cols="4" xl="3" class="mt-n3" justify="center" align="center">
              <h2>
                <strong class="primary--text">Sobre a BuyCo</strong><strong class="secondary--text">.</strong>
              </h2>
              <h4 class="text-justify primary--text pa-0 font-weight-light text-h6" style="line-height:1.3;">
                Ao longo de 10 anos de experiência, desenvolvemos uma
                metodologia que, com a ajuda da tecnologia e de um algoritmo
                de inteligência artificial, resultou em uma poderosa
                calculadora para dizer o valor justo da sua empresa de forma
                100% digital e gratuita!
              </h4>
              <v-btn color="#E85D75" href="https://www.buyco.com.br/" class="dropShadow white--text" style="top: 2em" elevation="0"
                large>Acesse nosso site</v-btn>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item>
          <v-row justify="center" align="center">
            <v-col xl="2" lg="3" md="4" sm="5" justify="center" align="center">
              <v-card height="276px" width="280px" color="white" class="d-flex mx-4 rounded-lg zIndex0" elevation="6">
                <v-img
                  src="https://www.signupgenius.com/cms/socialMediaImages/tips-make-the-most-of-company-business-meetings-article-1200x800.jpg" />
              </v-card>
            </v-col>
            <v-col cols="6" sm="7" md="6" lg="5" xl="3" class="" justify="center" align="center">
              <h4 class="text-justify primary--text mt-10 font-weight-light text-h6" style="line-height:1.3;">
                A BuyCo. é especialista em avaliar empresas e vai te ajudar a
                descobrir quanto vale o seu maior patrimônio. Basta preencher
                os dados da sua empresa no nosso formulário e, em 5 minutos, o
                valor será enviado para o seu e-mail. Não se preocupe, seus
                dados serão usados unicamente para o cálculo! Essa calculadora
                é uma versão simplificada que considera poucos dados
                informados por você para estimar o valor da sua empresa e, por
                isso, a BuyCo. não se responsabiliza pelos dados fornecidos e
                gerados. Além disso, a calculadora não descarta a necessidade
                da contratação de um consultor BuyCo. especialista em
                avaliação de empresas.
              </h4>
              <v-btn color="primary" href="https://www.buyco.com.br/" class="dropShadowPrimary" style="top: 2em"
                elevation="0" large>Saiba mais</v-btn>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
    <!-- desktop fim -->
  </div>
</template>

<script>
export default {
  name: "Carousel",
  
  data() {
    return {
      carousel: 0,
      carouselDesktop: 0,
    }
  },
}
</script>