const axios = require('axios')

async function getCNPJ(cnpj){
    cnpj = cnpj.replace(/\D/g, '')
    let data = {
        func: 'createCNPJ',
        cnpj
    }
    let url = 'https://script.google.com/macros/s/AKfycbz-2_2IGEEZZi9WRG_NNbGQm7JnPfdByGGaXtyKc9cH8V0VBioN/exec'
    let res = await axios.post(url, JSON.stringify(data))
    return res.data
}

module.exports = {getCNPJ}