<template>
  <v-card class="my-8" color="#FFF8" width="450px" min-height="400px" elevation="0" rounded="lg"
    style=" backdrop-filter: blur(5px); filter: drop-shadow(2px 4px 5px rgba(52, 60, 89, 0.4));">

    <!-- Titulo -->
    <v-app-bar flat class="rounded-b-0" color="primary">
      <v-toolbar-title class="white--text pl-0" style="width: 100%">
        <div class="d-none d-md-block">
          <label class="textDesktop"> Calculadora de Valuation </label>
        </div>
        <div class="d-md-none">
          <label class="textMobile"> Calculadora de Valuation </label>
        </div>
      </v-toolbar-title>
    </v-app-bar>

    <!-- Progresso -->
    <v-row justify="center" v-if="!calculating">
      <v-col cols="11">
        <v-progress-linear rounded class="mt-6" :value="stepsProgress" :color="progressColor"
          height="7"></v-progress-linear>
        <div class="d-flex flex-row-reverse">
          <div style="color: primary" class="mr-4 mt-1">
            {{ currentStepIndex }} de {{ stepsLength }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-card height="200px" class="pt-1" @keydown.enter="enter()" color="#FFF0" flat>
      <!-- Steps -->
      <div v-if="!calculating">
        <!-- Loading -->
        <div v-if="loading" class="ma-10">
          <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
        </div>

        <!-- Step -->
        <component v-show="!loading" ref="stepComponent" v-model="validStep" :is="currentStep"
          :filledData="calculatorData" :linkDuvida="linkDuvida" @update="update" @saveData="saveStepData"
          @skipStep="skipStep"></component>
      </div>
      
      <!-- After Steps -->
      <div v-else>
        <Calculating v-if="!done" :embedUrl="embedUrl" />
        <Done v-else :profile="profile" />
      </div>

    </v-card>

    <StepButtons v-if="!updateButton" :nextDisabled="!validStep" :previousDisabled="this.currentStepIndex == 0" :inLastStep="this.currentStepIndex == this.stepsLength-1"
      :calculating="calculating" :done="done" :callToAction="profile.call_to_action" @next="next" @previous="previous"
      @restart="restart" @openResult="openResult" />

  </v-card>
</template>
<script>
import calculatorLead from "@/service/calculatorLead.js"

import StepButtons from "@/components/Calculator/StepButtons.vue"
// Steps
import Motivo from "@/components/Calculator/Steps/Motivo.vue"
import TipoNegocio from "@/components/Calculator/Steps/TipoNegocio.vue"
import CNPJ from "@/components/Calculator/Steps/CNPJ.vue"
import FancyName from "@/components/Calculator/Steps/FancyName.vue"
import City from "@/components/Calculator/Steps/City.vue"
import Age from "@/components/Calculator/Steps/Age.vue"
import MarcaRegistrada from "@/components/Calculator/Steps/MarcaRegistrada.vue"
import Faturamento from "@/components/Calculator/Steps/Faturamento.vue"
import Lucro from "@/components/Calculator/Steps/Lucro.vue"
import Estoque from "@/components/Calculator/Steps/Estoque.vue"
import Imobilizado from "@/components/Calculator/Steps/Imobilizado.vue"
import Investimento from "@/components/Calculator/Steps/Investimento.vue"
import Dividas from "@/components/Calculator/Steps/Dividas.vue"
import Funcionarios from "@/components/Calculator/Steps/Funcionarios.vue"
import Momento from "@/components/Calculator/Steps/Momento.vue"
import Quase from "@/components/Calculator/Steps/Quase.vue"
import Nome from "@/components/Calculator/Steps/Nome.vue"
import Email from "@/components/Calculator/Steps/Email.vue"
import Phone from "@/components/Calculator/Steps/Phone.vue"
// AfterSteps
import Calculating from "@/components/Calculator/AfterSteps/Calculating.vue"
import Done from "@/components/Calculator/AfterSteps/Done.vue"

export default {
  name: "Calculator",
  components: {
    StepButtons,
    // Steps
    Motivo,
    TipoNegocio,
    CNPJ,
    FancyName,
    City,
    Age,
    MarcaRegistrada,
    Faturamento,
    Lucro,
    Estoque,
    Imobilizado,
    Investimento,
    Dividas,
    Funcionarios,
    Momento,
    Quase,
    Nome,
    Email,
    Phone,
    // AfterSteps
    Calculating,
    Done,
  },
  data() {
    return {
      currentStepIndex: 0,
      steps: [
        "Motivo",
        "TipoNegocio",
        "CNPJ",
        "FancyName",
        "City",
        "Age",
        "MarcaRegistrada",
        "Faturamento",
        "Lucro",
        "Estoque",
        "Imobilizado",
        "Investimento",
        "Dividas",
        "Funcionarios",
        "Momento",
        "Quase",
        "Nome",
        "Phone",
        "Email",
        
      ],
      updateButton: false,
      validStep: false,

      calculatorData: {},
      calculating: false,
      done: false,

      linkDuvida:
        "https://api.whatsapp.com/send?phone=553183532076&text=Olá! Gostaria de saber mais sobre a avaliação da minha empresa",
      profile: {
        id: "c8684b81-7ab4-4406-8658-ea0d9adf4b96",
        video: "Xvc_9uOM5G8",
        text: "Sua avaliação está pronta! Você receberá o resultado no seu email em até 5 minutos.",
        call_to_action: "Preciso de ajuda",
        url: "https://api.whatsapp.com/send?phone=553183532076&text=Olá! Gostaria de saber mais sobre a avaliação da minha empresa.",
        time: 130
      },
    }
  },

  async mounted() {
    if (this.$route.params.profile_id) {
      history.pushState(null, 'Calculadora Buyco', '/')
    }
  },

  methods: {
    async enter() {
      if (!this.validStep || this.calculating) return
      await this.next()
    },
    update(valid) { //TODO: Testar se, sem essa função aqui, os botões funcionam certo no safari
      this.updateButton = true
      setTimeout(() => {
        this.updateButton = false
      }, 20)
    },
    restart() {
      this.calculatorData = {}
      this.calculating = false
      this.done = false

      this.$store.dispatch('changeLoading', false)
      this.$store.dispatch('resetCompanyData')

      this.currentStepIndex = 0
      this.$router.push('/')
    },
    openResult() {
      window.open(this.profile.url, "_blank");
    },
    async calculate() {
      this.$store.dispatch('changeLoading', true)
      this.calculating = true

      let saveData = new calculatorLead(this.calculatorData, this.$route.query)
      await saveData.init()

      this.profile = saveData.profile ? saveData.profile : this.profile
      let profile_url = `/${this.profile.id}`
      history.pushState(null, 'Calculadora Buyco', profile_url);
      this.$gtag.event('conversion', { 'send_to': 'AW-712662130/t9ATCN-j3PIBEPK46dMC' })
      fbq('track', 'Lead')

      this.$store.dispatch('changeLoading', false)
      setTimeout(() => {
        this.done = true
      }, this.profile.time * 1000);

    },
    async skipStep() {
      switch (this.$store.state.stepCommand) {
        case 'next':
          await this.next()
          break
        case 'previous':
          this.previous()
          break
      }
    },
    async next() {
      this.$store.dispatch('changeButtonCommand', 'next')
      if (this.currentStepIndex >= (this.stepsLength - 1)) this.calculate()
      switch (this.currentStep) {
        case 'CNPJ':
          if (this.$store.state.companyCNPJ == this.calculatorData['cnpj']) break
          this.$store.dispatch('changeLoading', true)
          await this.$store.dispatch('searchCompanyData', this.calculatorData['cnpj'])
          this.$store.dispatch('changeLoading', false)
          break
      }
      this.currentStepIndex = Math.min(this.currentStepIndex + 1, this.stepsLength - 1)
      this.update()
    },
    previous() {
      this.$store.dispatch('changeButtonCommand', 'previous')
      this.currentStepIndex = Math.max(this.currentStepIndex - 1, 0)
      this.update()
    },
    saveStepData(data) {
      this.calculatorData = { ...this.calculatorData, ...data }
    },
  },

  computed: {
    stepsLength() {
      return this.steps.length
    },
    currentStep() {
      return this.steps[this.currentStepIndex]
    },
    stepsProgress() {
      return (this.currentStepIndex / this.stepsLength) * 100;
    },
    progressColor() {
      return "hsl(" + this.stepsProgress + ", 80%, 45%)";
    },

    loading() {
      return this.$store.state.loading
    },

    embedUrl() {
      return (
        "https://www.youtube.com/embed/" +
        this.profile.video +
        "?controls=0&autoplay=1&disablekb=1&fs=0&iv_load_policy=3&modestbranding=1&showinfo=0&rel=0&html5=1&cc_load_policy=1"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.textDesktop {
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  color: white;
}

.textMobile {
  font-family: Roboto;
  font-size: 1.16em;
  font-style: normal;
  font-weight: bold;
  color: white;
}

.textDesktopBlack {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>