<template>
  <v-app>
    <meta name="viewport" content="height=device-height, 
                      width=device-width, initial-scale=1.0, 
                      minimum-scale=1.0, maximum-scale=1.0, 
                      user-scalable=no, target-densitydpi=device-dpi">
    <BuycoAppBar />

    <router-view class="mt-7 mb-n12"></router-view>

    <!-- text strip -->
    <v-app-bar flat class="rounded-b-0 " height="70em" color="primary">
      <v-row>
        <v-col cols="12" align="center">
          <div class="d-none d-md-block">
            <label class="textDesktop" justify="center">
              Descubra o valor da sua empresa!
            </label>
          </div>
          <div class="d-md-none">
            <label class="textMobile" justify="center">
              Descubra o valor da sua empresa!
            </label>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <Carousel />

    <v-divider class="primary mx-16 mb-10"></v-divider>

    <Opinions />

    <BuycoFooter />

    <!-- whats button -->
    <v-fab-transition>
      <v-btn color="#0DB941D0" fab fixed large dark bottom right elevation="4" style="backdrop-filter: blur(2px);"
        @click="whats()">
        <v-icon large>mdi-whatsapp</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import BuycoAppBar from "@/components/BuycoApp/BuycoAppBar.vue"
import BuycoFooter from "@/components/BuycoApp/BuycoFooter.vue"
import Carousel from "@/components/Carousel.vue"
import Opinions from "@/components/Opinions.vue"

export default {
  name: "App",

  components: {
    BuycoAppBar,
    BuycoFooter,
    Carousel,
    Opinions,
  },
  methods:{
    whats(){
      window.open("https://api.whatsapp.com/send?phone=+553136583381&text=Olá!", "_blank");
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");

.colorBackground {
  background-color: #e4e9ed;
}

.main {
  background-color: #e4e9ed;
  background-attachment: fixed;
}

body {
  font-family: "Poppins", sans-serif;
}

.aspas {
  font-family: "Rozha One", serif;
  font-size: 120px;
  margin-bottom: -195px;
  position: relative;
  bottom: 60px;
  left: 200px;
  text-shadow: 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000;
}

.aspasRotate {
  font-family: "Rozha One", serif;
  font-size: 120px;
  margin-bottom: -195px;
  position: relative;
  bottom: 142px;
  left: 200px;
  text-shadow: 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000;
  transform: rotate(-180deg);
}

.aspasDesktop {
  font-family: "Rozha One", serif;
  font-size: 300px;
  margin-bottom: -450px;
  position: relative;
  bottom: 140px;
  left: 30px;
  text-shadow: 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000;
  z-index: -1;
}

.aspasRotateDesktop {
  font-family: "Rozha One", serif;
  font-size: 300px;
  margin-bottom: -450px;
  position: relative;
  bottom: 308px;
  left: 30px;
  text-shadow: 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000, 0 0 1px #000,
    0 0 1px #000, 0 0 1px #000, 0 0 1px #000;
  transform: rotate(-180deg);
  z-index: -1;
}

.arrows {
  bottom: 250px;
  right: 80px;
  margin-bottom: -140px;
}

.meioCirculo {
  bottom: 30px;
  left: 30px;
  margin-bottom: -30px;
  gap: 0px;
  animation: 45s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s infinite normal none running dVAlQX;
}

@keyframes dVAlQX {
  0% {}

  33% {
    transform: translate(53px, 26px), rotate(10deg);
  }

  66% {
    translate: (32px, 74px), rotate(50deg);
  }

  100% {}
}

.dropShadow {
  filter: drop-shadow(0px 4px 10px rgba(232, 93, 117, 0.3));
}

.dropShadowPrimary {
  filter: drop-shadow(0px 4px 10px rgba(107, 93, 232, 0.3));
}

.calculadora {
  font-weight: 350;
}

.zIndex1 {
  position: relative;
  z-index: 1;
}

.zIndex0 {
  position: relative;
  z-index: 0;
}

.zIndex-1 {
  position: relative;
  z-index: -1;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.textDesktop {
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  color: white;
}

.textMobile {
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  color: white;
}

.textDesktopBlack {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}
</style>