// axios
import axios from 'axios'

function resizedataURL(datas, wantedWidth, wantedHeight) {
  return new Promise(async function (resolve, reject) {

    // We create an image to receive the Data URI
    var img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      var dataURI = canvas.toDataURL();

      // This is the return of the Promise
      resolve(dataURI);
    };

    // We put the Data URI in the image's src attribute
    img.src = datas;

  })
}
export default class Upload {
  image = async (fileBase64, bucket, subfolder, width, height) => {
    try {
      let params = {
        bucket: bucket,
        extension: 'png',
        content_type: 'image/png',
        sub_folders: subfolder
      }
      let newFileBase64 = (!!width && !!height) ? await resizedataURL(fileBase64, width, height) : fileBase64
      // encode params
      let encodeParams = this.parseParams(params)
      // get url signet for upload
      let responseSigned = await this.getSignedURl(encodeParams)
      // send file
      let response = await this.sendFile(responseSigned.uploadURL, params.content_type, newFileBase64)

      if (response.status === 200) return responseSigned
    } catch (err) {
      throw (err)
    }
  }
  
  pdf = async (file, fileBase64, bucket, subfolder) => {
    try {
      let params = {
        bucket: bucket,
        extension: file.type.split('/').pop(),
        content_type: file.type,
        sub_folders: subfolder
      }
      // encode params
      let encodeParams = this.parseParams(params)
      // get url signet for upload
      let responseSigned = await this.getSignedURl(encodeParams)
      // // send file
      let response = await this.sendFile(responseSigned.uploadURL, params.content_type, fileBase64)

      if (response.status === 200) return responseSigned
    } catch (err) {
      throw (err)
    }
  }

  file = async (file, fileBase64, bucket, subfolder) => {
    try {
      // console.log(file.name.split('.').pop())
      let params = {
        bucket: bucket,
        extension: file.name.split('.').pop(),
        content_type: file.type,
        sub_folders: subfolder
      }
      // encode params
      let encodeParams = this.parseParams(params)
      // get url signet for upload
      let responseSigned = await this.getSignedURl(encodeParams)
      // // send file
      let response = await this.sendFile(responseSigned.uploadURL, params.content_type, fileBase64)

      if (response.status === 200) return responseSigned
    } catch (err) {
      throw (err)
    }
  }

  valuation = async (file, cnpj, fileBase64) => {
    try {
      let params = {
        bucket: 'buyco.valuation.files',
        extension: file.type.split('/').pop(),
        content_type: file.type,
        sub_folders: cnpj
      }
      // encode params
      let encodeParams = this.parseParams(params)
      // get url signet for upload
      let responseSigned = await this.getSignedURl(encodeParams)
      // // send file
      let response = await this.sendFile(responseSigned.uploadURL, params.content_type, fileBase64)

      if (response.status === 200) return responseSigned
    } catch (err) {
      throw (err)
    }
  }

  parseParams(params) {
    const strParams = JSON.stringify(params)
    const encodeParams = Buffer.from(strParams).toString('base64')

    return encodeParams
  }

  async getSignedURl(encodeParams) {
    let domain = 'https://ut9vsgl2mh.execute-api.us-east-1.amazonaws.com/dev/signed-url'
    let url = `${domain}/${encodeParams}`
    let res = await axios.get(url)

    return res.data
  }

  async sendFile(uploadURL, contentType, file) {
    let buffer = ''
    // console.log('contentType',contentType)
    // console.log('file',file)

    // if (contentType === 'application/pdf') buffer = Buffer.from(file.replace(/^data:application\/\w+;base64,/, ''), 'base64')
    // else buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64')
    buffer = Buffer.from(file.split('base64,').pop(), 'base64')
    // console.log('buffer',)

    const configOptions = {
      headers: {
        'Content-Type': contentType,
        'Content-Encoding': 'base64'
      }
    }

    return axios.put(uploadURL, buffer, configOptions)
  }
}
