<template>
  <div class="px-12">
    <h2 class="textDesktopBlack">Quase lá!</h2>
    <br />
    <label class="textDesktopBlack">Você está perto de saber o valor da sua empresa! <br />
      Antes de finalizar, precisamos de algumas informações para te entregar o resultado!
    </label>
  </div>
</template>
    
<script>
export default {
  name: "Quase",
  components: {
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: true,
    }
  },
  computed: {
  },
  beforeMount() {
    if (this.$store.state.stepCommand == 'previous') this.$emit('skipStep')
  },
  mounted() {
    this.$emit('input', this.valid)
  },
  methods: {
  },
}
</script>