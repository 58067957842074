<template>
  <div>
    <!-- opinioes mobile começo -->
    <div class="d-md-none">
      <v-row justify="center" align="center">
        <v-col cols="10" justify="center" align="start" class="mt-5 mb-n9">
          <h2 class="primary--text">
            <strong>Veja a opinião dos nossos clientes</strong><strong class="secondary--text">.</strong>
          </h2>
        </v-col>
        <v-col cols="12" justify="center" align="center">
          <v-carousel v-model="opinioes" height="200px" class="mt-4" hide-delimiters :show-arrows="false">
            <v-carousel-item v-for="(opinion, i) in opinions" :key="i">
              <v-card height="170px" width="320px" color="white" class="my-10 mb-6 mx-4 rounded-lg" elevation="6">
                <v-row class="mb-3">
                  <div class="aspas white--text">“</div>
                  <div class="aspasRotate white--text">“</div>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="4" justify="center" align="center">
                    <v-card absolute class="d-flex rounded-circle pa-0 white" elevation="0" width="90px" height="90px">

                      <v-img :src="images[i]" />
                    </v-card>
                  </v-col>
                  <v-col cols="7" justify="center" align="center" class="ml-n6 pa-0 px-2">
                    <v-card-text class="grey--text">
                      {{ opinion }}
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row justify="end" align="end" class="mt-n7">
                  <v-col cols="12" justify="end" align="start">
                    <v-card-text class="grey--text">
                      <h3>
                        <strong class="primary--text">{{ names[i] }} |</strong>
                        {{ surnames[i] }}
                      </h3>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="10" justify="center" align="center" class="mt-n4 mb-8">
          <v-btn class="mx-2" fab dark small color="primary" @click="backOpinioes()">
            <v-icon dark> mdi-chevron-left </v-icon>
          </v-btn>

          <v-icon v-for="(opinion, i) in opinions" :key="i" class="mx-2 grey--icon"
            v-bind:class="{ 'primary--text': opinioes === i }" size="12px">
            mdi-circle
          </v-icon>

          <v-btn class="mx-2" fab dark small color="primary" @click="nextOpinioes()">
            <v-icon dark> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- opinioes mobile fim -->

    <!-- opinioes desktop começo -->
    <div class="d-none d-md-block">
      <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center" class="my-5" style="font-size: 2em">
          <strong>Veja a opinião dos nossos clientes</strong><strong class="secondary--text">.</strong>
        </v-col>

        <v-col cols="1" justify="center" align="center">
          <v-card absolute class="d-flex rounded-circle pa-0 white mb-15 mt-n15" elevation="3" width="70px" height="70px">
            <v-img v-if="opinioes !== 0" :src="images[opinioes - 1]" />
            <v-img v-if="opinioes === 0" :src="images[opinioes + 2]" />
          </v-card>
        </v-col>

        <v-col cols="4" justify="center" align="center">
          <v-carousel v-model="opinioes" height="320px" hide-delimiters :show-arrows="false">
            <v-carousel-item v-for="(opinion, i) in opinions" :key="i">
              <v-card height="250px" width="300px" class="my-4 mx-4 mt-15" style="background: rgba(0, 0, 0, 0)"
                elevation="0">
                <v-row class="mb-3">
                  <div class="aspasDesktop white--text">“</div>
                  <div class="aspasRotateDesktop white--text">“</div>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="12" justify="center" align="center">
                    <v-card absolute class="d-flex rounded-circle pa-0 white" elevation="6" width="110px" height="110px">
                      <v-img :src="images[i]" />
                    </v-card>
                  </v-col>
                  <v-col cols="12" justify="center" align="center" class="pa-0">
                    <v-card-text class="grey--text" style="font-size: 1.4em">
                      {{ opinion }}
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" justify="start" align="center" class="mt-n5">
                    <v-card-text class="grey--text">
                      <h3>
                        <strong class="primary--text">{{ names[i] }} |</strong>
                        {{ surnames[i] }}
                      </h3>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col cols="1" justify="center" align="center">
          <v-card absolute class="d-flex rounded-circle pa-0 white mb-15 mt-n15" elevation="3" width="70px" height="70px">
            <v-img v-if="opinioes !== 2" :src="images[opinioes + 1]" />
            <v-img v-if="opinioes === 2" :src="images[opinioes - 2]" />
          </v-card>
        </v-col>

        <v-col cols="10" justify="center" align="center" class="mt-3" style="margin-bottom: 100px">
          <v-btn class="mx-3" style="filter: drop-shadow(0px 4px 8px rgba(232, 93, 117, 0.5))" elevation="0" fab dark
            color="#e85d75" @click="backOpinioes()">
            <v-icon dark size="2.7em"> mdi-chevron-left </v-icon>
          </v-btn>

          <v-icon v-for="(opinion, i) in opinions" :key="i" class="mx-3" v-bind:class="{
            'red--text text--lighten-1': opinioes === i,
            'red--text text--lighten-4': opinioes !== i,
          }" size="12px">
            mdi-circle
          </v-icon>

          <v-btn class="mx-3" style="filter: drop-shadow(0px 4px 8px rgba(232, 93, 117, 0.5))" elevation="0" fab dark
            color="#e85d75" @click="nextOpinioes()">
            <v-icon dark size="2.7em"> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- opinioes desktop fim -->
  </div>
</template>

<script>
export default {
  name: "Carousel",

  data() {
    return {
      links: ["https://www.facebook.com/buycobrasil/", "https://www.youtube.com/channel/UCWLwR43h9Qgk3SHUJc7J87Q", "https://www.linkedin.com/company/buyco-brasil/", "https://www.instagram.com/buycobrasil/"],
      icons: ["mdi-facebook", "mdi-youtube", "mdi-linkedin", "mdi-instagram"],
      images: [
        "https://i0.wp.com/wp-buyco-2021.s3.amazonaws.com/wp-content/uploads/2021/04/luciana.png",
        "https://i0.wp.com/wp-buyco-2021.s3.amazonaws.com/wp-content/uploads/2021/04/ricardo.jpg",
        "https://i1.wp.com/wp-buyco-2021.s3.amazonaws.com/wp-content/uploads/2021/04/bruno.png",
      ],
      opinions: [
        "Encontrei na BuyCo. a oportunidade de empreender no ramo de restaurantes.",
        "Uma avaliação técnica que me passa segurança para apresentar para possíveis compradores.",
        "Parabéns pela iniciativa de vocês e nobre causa em trabalhar com as MPEs brasileiras!",
      ],
      names: ["Luciana", "Ricardo Carlos", "Bruno"],
      surnames: ["Ximenes", "Samuel", "Bentes"],
      opinioes: 0,
      timer: null,
    }
  },
  
  mounted() {
    this.timer = setInterval(() => {
      this.nextOpinioes();
    }, 5000)
  },
  
  methods: {
    nextOpinioes() {
      this.opinioes = (this.opinioes + 1) % this.opinions.length
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.nextOpinioes();
      }, 5000)
    },
    backOpinioes() {
      this.opinioes = (this.opinioes + 2) % this.opinions.length
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.nextOpinioes();
      }, 5000)
    }
  },
}
</script>