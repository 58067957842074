
let getUrl = (key, bucket, w, h) => {
    const request = {
        bucket: bucket,
        key: key,
        edits: {
            resize: {
                width: w,
                height: h
            }
        }
    }

    const strRequest = JSON.stringify(request)
    const encRequest = Buffer.from(strRequest).toString('base64')
    const domain = 'https://d1wvggu0nvxp5o.cloudfront.net'

    let url = `${domain}/${encRequest}`

    return url
}

module.exports = {getUrl}