<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "Estoque",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          autofocus: true,
          type: "money",
          key: "estoque",
          label: "Estoque",
          monetary: true,
          dense: true,
          background: "#FFFD",
          outlined: true,
          rules: [(v) => !!v || "Insira o estoque da empresa"],
          doubt: {
            color: "#F1B71C33",
            iconColor: "warning",
            iconSize: "35px",
            size: "45px",
            class: "pa-1",
            title: "Estoque",
            text: "O estoque da sua empresa é a soma de todo o seu estoque de mercadorias para revenda, matérias-primas, embalagens, produtos acabados e materiais ou produtos para serem aplicados em serviços.",
            link: this.linkDuvida,
            linkText: "Ainda estou com dúvidas",
            close: "Entendi",
          },
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = {...this.filledData}
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>