<template>
  <div class="py-6">
    <v-parallax class="py-16" height="600" src="https://s3.amazonaws.com/buyco.assets/images/calculator-backgroud.jpg">
      <v-row justify="center" align="center" class="ma-1">
        <v-col justify="center" align="center" cols="12">
          <Calculator />
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
import axios from 'axios'
import Calculator from '@/components/Calculator/Calculator.vue'

export default {
  name: 'Home',
  components: {
    Calculator,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() { // Loading Confetti
    axios.get("https://s3.amazonaws.com/buyco.assets/images/ad2-static.png")
    axios.get("https://s3.amazonaws.com/buyco.assets/images/ad2-gif-min.gif")
  },
  computed: {
  }
}
</script>