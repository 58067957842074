<template>
  <div>
    <label class="d-flex ml-7">Idade da Empresa</label>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>
    
<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "Age",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "radio",
          key: "idade",
          items: [
            "Menos de 1 ano",
            "De 1 a 5 anos",
            "De 6 a 10 anos",
            "De 11 a 15 anos",
            "Mais de 15 anos",
          ],
          rules: [(v) => !!v || "Selecione a idade da empresa"],
          dense: true,
          outlined: true,
          stylization:
            "margin-bottom: -50px; margin-top: -25px; margin-left: 20px; text-color: primary;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
    this.checkStoreAge()
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
    checkStoreAge() {
      if (!this.$store.state.companyAge) return
      this.dataForms['idade'] = this.$store.state.companyAge //TODO: Conferir se aqui precisa retornar string ou o número com a idade (olha como o input tá definido e como o store pega a idade do cnpj) 
      this.$emit('saveData', this.dataForms)
      this.$emit('skipStep')
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>