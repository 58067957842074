<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"
import gql from "graphql-tag";

export default {
  name: "TipoNegocio",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
      types: [],
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "autocomplete",
          key: "tipo",
          label: "Tipo de Negócio",
          dense: true,
          items: this.types,
          background: "#FFFD",
          hideDetails: false,
          outlined: true,
          rules: [(v) => !!v || "Insira o tipo do seu negócio"],
          doubt: {
            color: "#F1B71C33",
            iconColor: "warning",
            iconSize: "35px",
            size: "45px",
            class: "pa-1",
            title: "Tipo de Negócio",
            text: "<p>Aqui você deve selecionar o tipo do seu negócio. Por exemplo: </p> <ul> <li>Salão de cabelereiro</li> <li> Restaurante </li> <li> Pizzaria </li> </ul><br> <p> Caso seu tipo de negócio não esteja listado, você pode escolher entre: </p> <ul> <li> Outros Serviços </li> <li> Outros Comércios </li> <li> Outros Indústrias </li> </ul><br><p> E então preencher qual o tipo de sua empresa.</p>",
            link: this.linkDuvida,
            linkText: "Ainda estou com dúvidas",
            close: "Entendi",
          },
          stylization: "margin-bottom: -35px;",
        },
        {
          autofocus: true,
          type: "text",
          key: "outroTipo",
          label: "Escreva o tipo do negócio",
          dense: true,
          hide: this.choseOther,
          background: "#FFFD",
          rules: [
            (v) => !!v || this.choseOther || "Insira o tipo do negócio",
          ],
          hideDetails: false,
          outlined: true,
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
    choseOther() {
      // TODO: Encontrar maneira melhor de checar se "outros" foi escolhido
      return this.dataForms.tipo != "c6e9fad9-27ca-421f-a031-c1eee7bcc35c" && this.dataForms.tipo != "3d43876f-eff2-4c90-8d97-69e7747b46ba" && this.dataForms.tipo != "7a8aeada-eda0-4dcd-a916-291cddeee289"
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
    this.types = this.$store.state.types
  },
  async mounted() {
    if (this.types > 0) return
    this.$store.dispatch('changeLoading', true)
    await this.getTypes()
    this.$store.dispatch('saveTypes', this.types)
    this.$store.dispatch('changeLoading', false)
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
    async getTypes() {
      try {
        let query = await this.$apollo.query({
          query: gql`
            query MyQuery {
              company_types(order_by: { name: asc }) {
                id
                name
                branchByBranch {
                  sector
                }
              }
            }
          `,
        })
        let result = query.data.company_types
        this.types = []
        result.forEach((x) => {
          this.types.push({
            text: x.name.replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
            value: x.id,
          })
        })
      } catch (error) { //TODO: Tratar erro na query
        console.log("error: ", error)
      }
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>