import Vue from 'vue'
import Vuex from 'vuex'
import { getCNPJ } from '@/service/getCnpj.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    stepCommand: '',

    companyCNPJ: '',
    companyData: [],
    companyAge: '',
    companyCity: '',
    companyName: '',
    companyFancyName: '',

    cities: [],
    reasons: [],
    types: [],
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading
    },
    setButtonCommand(state, command) {
      state.stepCommand = command
    },

    clearCompanyData(state) {
      state.companyCNPJ = ''
      state.companyData = []
      state.companyAge = ''
      state.companyCity = ''
      state.companyName = ''
      state.companyFancyName = ''
    },
    async saveCompanyData(state, payload) {
      let cnpj = payload['cnpj']
      let cnpjData = payload['cnpjData']
      state.companyCNPJ = cnpj
      state.companyData = cnpjData
      let now = new Date()
      state.companyAge = parseInt(now.getFullYear()) - parseInt(cnpjData[12].slice(-4)) + 1
      state.companyCity = (cnpjData[10].toLowerCase()).replace(/(^|\s)\S/g, (l) => l.toUpperCase()) + " (" + cnpjData[11] + ")"
      state.companyName = cnpjData[2]
      state.companyFancyName = cnpjData[18]
    },

    setCities(state, cities) {
      state.cities = cities
    },
    setReasons(state, reasons) {
      state.reasons = reasons
    },
    setTypes(state, types) {
      state.types = types
    },
  },
  actions: {
    changeLoading(context, isLoading) {
      context.commit('setLoading', isLoading)
    },
    changeButtonCommand(context, command) {
      context.commit('setButtonCommand', command)
    },

    async searchCompanyData(context, cnpj) {
      context.commit('clearCompanyData')
      let cnpjRequest = await getCNPJ(cnpj.replace(/\D/g, '')) // TODO: Adicionar try catch
      let cnpjData = cnpjRequest.result
      if (cnpjData.length >= 19) {
        await context.commit('saveCompanyData', {'cnpj': cnpj, 'cnpjData': cnpjData})
      }
    },
    resetCompanyData(context) {
      context.commit('clearCompanyData')
    },
    
    saveCities(context, cities) {
      context.commit('setCities', cities)
    },
    saveReasons(context, reasons) {
      context.commit('setReasons', reasons)
    },
    saveTypes(context, types) {
      context.commit('setTypes', types)
    },
  },
  modules: {
  }
})
