<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
      <v-row class="mt-6 mr-4" justify="center" align="center">
        <v-col cols="12" justify="center" align="center">
          <v-icon class="mb-1" size="30px">mdi-alert-circle</v-icon>
          <label class="textDesktopBlack">
            Caso não tenha dívida bancária, pule este passo.</label>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "Dividas",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          autofocus: true,
          type: "money",
          key: "dividas",
          label: "Dívidas Bancárias?",
          monetary: true,
          dense: true,
          background: "#FFFD",
          outlined: true,
          doubt: {
            color: "#F1B71C33",
            iconColor: "warning",
            iconSize: "35px",
            size: "45px",
            class: "pa-1",
            title: "Dívidas Bancárias",
            text: "Neste campo você deve preencher quanto de dívida bancária a empresa possui. Caso não tenha nenhuma dívida bancária, o valor pode ser zero.",
            link: this.linkDuvida,
            linkText: "Ainda estou com dúvidas",
            close: "Entendi",
          },
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>