<template>
  <div>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>
    
<script>
import TabForms from "@/components/forms/TabForms.vue"
import gql from "graphql-tag"

export default {
  name: "City",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
      cities: [],
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "autocomplete",
          key: "cidade",
          label: "Cidade",
          dense: true,
          items: this.cities,
          background: "#FFFD",
          hideDetails: false,
          outlined: true,
          rules: [(v) => !!v || "Selecione a sua cidade"],
          stylization: "margin-bottom: -35px;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
    this.cities = this.$store.state.cities
    this.checkStoreCity()
  },
  async mounted() {
    if (this.cities > 0) return
    this.$store.dispatch('changeLoading', true)
    await this.getCities()
    this.$store.dispatch('saveCities', this.cities)
    this.$store.dispatch('changeLoading', false)
    this.checkStoreCity()
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
    checkStoreCity() {
      if (!this.$store.state.companyCity) return
      if (!this.$store.state.cities) return
      let foundCities = this.$store.state.cities.filter((cityObject) => cityObject.text == this.$store.state.companyCity)
      if (foundCities.length == 0) return
      this.dataForms['cidade'] = foundCities[0].value
      this.$emit('saveData', this.dataForms)
      this.$emit('skipStep')
    },
    async getCities() {
      try {
        let query = await this.$apollo.query({
          query: gql`
              query MyQuery {
                cities(order_by: { name: asc }) {
                  id
                  name
                  stateByState {
                    initials
                  }
                }
              }
            `,
        })
        let result = query.data
        this.cities = []
        result.cities.forEach((x) => {
          this.cities.push({
            text: x.name + " (" + x.stateByState.initials + ")",
            value: x.id,
          })
        })
      } catch (error) { //TODO: Tratar erro na query
        console.log("error: ", error)
      }
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>