import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
// Config environments

const config = {
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  endPointGraphQL: process.env.VUE_APP_ENDPOINT_GRAPHQL
}

const authLink = setContext(async (_, { headers }) => {

  // phone
  // let phone = localStorage.getItem('CognitoIdentityServiceProvider.' + config.userPoolWebClientId + '.LastAuthUser')
  // let token = localStorage.getItem('CognitoIdentityServiceProvider.' + config.userPoolWebClientId + '.' + phone + '.idToken')
  return {
    headers: {
      ...headers,
      authorization: ''
      // authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = new HttpLink({ uri: config.endPointGraphQL })

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

Vue.use(VueApollo)

export default apolloProvider
