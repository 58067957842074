const axios = require("axios");

async function sendToHubspot(
  conversion_identifier,
  name,
  email,
  phone,
  mailing,
  city,
  state,
  idade,
  cnpj,
  faturamento,
  lucro,
  motivodaavaliacao,
  outroMotivo,
  tipodonegocio,
  razaosocial,
  setor,
  porte,
  funcionarios,
  momento,
  marcaRegistrada,
  traffic_source,
  traffic_medium,
  traffic_campaign,
  dividas,
  lead_id
) {
  let marca_registrada = marcaRegistrada.toLowerCase() == "sim";
  let founded_year = new Date().getFullYear() - idade;
  let lucro_num = Number(lucro.replace(/\D/g, "")) / 100;
  let faturamento_num = Number(faturamento.replace(/\D/g, "")) / 100;
  let dividas_num = Number(dividas.replace(/\D/g, "")) / 100;

  let faturamento_anual_da_empresa = "";
  if (faturamento_num * 12 < 1000000) {
    faturamento_anual_da_empresa = "Até R$1M";
  } else if (faturamento_num * 12 < 5000000) {
    faturamento_anual_da_empresa = "Entre R$1M e R$5M";
  } else if (faturamento_num * 12 < 10000000) {
    faturamento_anual_da_empresa = "Entre R$5M e R$10M";
  } else if (faturamento_num * 12 < 50000000) {
    faturamento_anual_da_empresa = "Entre R$10M e R$50M";
  } else if (faturamento_num * 12 < 100000000) {
    faturamento_anual_da_empresa = "Entre R$50M e R$100M";
  } else {
    faturamento_anual_da_empresa = "Acima de R$100M";
  }

  let porte_tratado = porte;
  switch (porte_tratado) {
    case "Pequeno":
      porte_tratado = "Pequena";
      break;
    case "Médio":
      porte_tratado = "Média";
      break;
  }

  let motivo_da_avaliacao = motivodaavaliacao;
  switch (motivo_da_avaliacao) {
    case "Alteração no quadro de sócios":
      motivo_da_avaliacao = "Alteração do quadro de sócios";
      break;
    case "Captação de investimento":
      motivo_da_avaliacao = "Captação de investimentos";
      break;
  }

  const url = "https://iejoizf62g.execute-api.us-east-1.amazonaws.com";

  const contact_data = {
    object: {
      object_type: "0-1",
      main_property: "email",
      properties: {
        firstname: name,
        email,
        phone,
        motivo_da_avaliacao,
        origem: "Calculadora",
        utm_source: traffic_source,
        utm_medium: traffic_medium,
        utm_campaign: traffic_campaign,
        calculadora_lead_id: lead_id
      },
    },
  };

  const company_data = {
    object: {
      object_type: "0-2",
      main_property: "cnpj",
      properties: {
        annualrevenue: faturamento_num * 12,
        city,
        cnpj,
        faturamento_anual_da_empresa,
        founded_year,
        lucro: lucro_num * 12,
        marca_registrada,
        numberofemployees: Number(funcionarios),
        porte__tamanho_: porte_tratado,
        ramo_de_atividade: setor,
        razao_social: razaosocial,
        state,
        tipo_do_negocio: tipodonegocio,
        quanto_sua_empresa_possui_de_dividas_: dividas_num,
      },
    },
  };

  const contact_response = await axios.post(
    `${url}/object/upsert`,
    contact_data
  );
  const company_response = await axios.post(
    `${url}/object/upsert`,
    company_data
  );

  const association_data = {
    association: {
      label: "Funcionario",
      from_object: {
        object_type: "0-1",
        object_id: contact_response.data.object.id,
      },
      to_object: {
        object_type: "0-2",
        object_id: company_response.data.object.id,
      },
    },
  };

  const association_response = await axios.post(
    `${url}/association/create`,
    association_data
  );

  return association_response;
}

module.exports = { sendToHubspot };
