<template>
  <div>
    <label class="d-flex ml-7">Momento da Empresa</label>
    <v-form v-model="valid" @submit.prevent>
      <TabForms :inputs="inputs" v-model="dataForms" @update="update" />
    </v-form>
  </div>
</template>
    
<script>
import TabForms from "@/components/forms/TabForms.vue"

export default {
  name: "Momento",
  components: {
    TabForms,
  },
  props: ["value", "linkDuvida", "filledData"],
  data() {
    return {
      valid: false,
      dataForms: {},
    }
  },
  computed: {
    inputs() {
      return [
        {
          type: "radio",
          key: "momento",
          items: [
            "Crescendo Muito",
            "Crescimento Moderado",
            "Estagnada",
            "Dificuldades Financeiras",
            "Não está em operação",
          ],
          rules: [(v) => !!v || "Selecione a opção que melhor descreve o momento da empresa"],
          dense: true,
          outlined: true,
          stylization:
            "margin-bottom: -50px; margin-top: -25px; text-color: primary;",
        },
      ]
    },
  },
  beforeMount() {
    this.dataForms = { ...this.filledData }
  },
  async mounted() {
  },
  methods: {
    update() {
      this.$emit('update', this.valid)
    },
  },
  watch: {
    valid(isValid) {
      this.$emit('input', isValid)
    },
    dataForms(data) {
      this.$emit('saveData', data)
    }
  },
}
</script>