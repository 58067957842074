import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import numeral from 'numeral'
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask';
import money from 'v-money';
import apolloProvider from './vue-apollo'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.use(VueMask);
Vue.use(VueTheMask);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: true
})

Vue.use(VueAnalytics, {
  id: 'UA-146676380-1',
  router,
  autoTracking: {
    exception: true
  }
})

Vue.use(VueGtag, {
  config: { id: 'AW-712662130' }
}, router)

numeral.register('locale', 'br', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  currency: {
      symbol: 'R$'
  }
});

// switch between locales
numeral.locale('br');

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
